class EditableAppPopup extends BasePopup {

    constructor(el) {
        super(el);
        this.title = this.$find('[data-title]');
        this.body = this.$find('[data-body]');
    }

    addListeners() {
        super.addListeners();
        EventBus.getInstance().addEventListener('changePopupContent', e => this.onChangePopupContent(e.detail));
    }

    onChangePopupContent({id, data}) {
        if (this.id !== id) {
            return;
        }

        if (this.title) {
            this.title.innerHTML = data.title ?? '';
        }
        this.body.innerHTML = data.body ?? '';
    }

}
